@font-face {
  font-family: AppFont;
  src: url("../../../../assets/fonts/Roboto-Regular.ttf"); //change url accordingly
}
@font-face {
  font-family: AppFontLight;
  src: url("../../../../assets/fonts/Roboto-Light.ttf"); //change url accordingly
}
@font-face {
  font-family: AppFontMedium;
  src: url("../../../../assets/fonts/Roboto-Medium.ttf"); //change url accordingly
}
ion-icon {
  &[class*="menu_icon-"] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1.1em;
    height: 1.1em;
  }

  &[class*="ACTIVITIES"] {
    mask-image: url(../../../../assets/imgs/menu_activities.svg);
  }

  &[class*="HOME"] {
    mask-image: url(../../../../assets/imgs/menu_hotel.svg);
  }

  &[class*="HOTEL-MAP"] {
    mask-image: url(../../../../assets/imgs/menu_map.svg);
  }

  &[class*="ACCOUNT"] {
    mask-image: url(../../../../assets/imgs/menu_profile.svg);
  }

  &[class*="DASHBOARD"] {
    mask-image: url(../../../../assets/imgs/menu_dashboard.svg);
  }

  &[class*="SETTINGS"] {
    mask-image: url(../../../../assets/imgs/menu_settings.svg);
  }

  &[class*="HELP"] {
    mask-image: url(../../../../assets/imgs/menu_help.svg);
  }

  &[class*="PROFILE"] {
    mask-image: url(../../../../assets/imgs/menu_profile.svg);
  }

  &[class*="Tennis"] {
    mask-image: url(../../../../assets/imgs/reservations_Tennis.svg);
  }
  &[class*="Gym"] {
    mask-image: url(../../../../assets/imgs/reservations_Gym.svg);
  }

  &[class*="weather-01d"] {
    mask-image: url(../../../../assets/imgs/weather-01d.svg);
  }
  &[class*="weather-01n"] {
    mask-image: url(../../../../assets/imgs/weather-01n.svg);
  }
  &[class*="weather-02d"] {
    mask-image: url(../../../../assets/imgs/weather-02d.svg);
  }
  &[class*="weather-02n"] {
    mask-image: url(../../../../assets/imgs/weather-02n.svg);
  }
  &[class*="weather-03"] {
    mask-image: url(../../../../assets/imgs/weather-03.svg);
  }
  &[class*="weather-04"] {
    mask-image: url(../../../../assets/imgs/weather-04.svg);
  }
  &[class*="weather-09"] {
    mask-image: url(../../../../assets/imgs/weather-09.svg);
  }
  &[class*="weather-10"] {
    mask-image: url(../../../../assets/imgs/weather-10.svg);
  }
  &[class*="weather-11"] {
    mask-image: url(../../../../assets/imgs/weather-11.svg);
  }
  &[class*="weather-12"] {
    mask-image: url(../../../../assets/imgs/weather-12.svg);
  }
  &[class*="weather-50d"] {
    mask-image: url(../../../../assets/imgs/weather-50d.svg);
  }
  &[class*="weather-50n"] {
    mask-image: url(../../../../assets/imgs/weather-50n.svg);
  }

  &[class*="FEEDBACK"] {
    mask-image: url(../../../../assets/imgs/custom-feedback.svg);
  }

  &[class*="LOGO-facebook"] {
    mask-image: url(../../../../assets/imgs/logo-facebook.svg);
  }
  &[class*="LOGO-instagram"] {
    mask-image: url(../../../../assets/imgs/logo-instagram.svg);
  }
  &[class*="LOGO-youtube"] {
    mask-image: url(../../../../assets/imgs/logo-youtube.svg);
  }
  &[class*="LOGO-tripadvisor"] {
    mask-image: url(../../../../assets/imgs/logo-tripadvisor.svg);
  }

  &[class*="ROOM"] {
    mask-image: url(../../../../assets/imgs/menu_room.svg);
  }
}

.reservation-form {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8) !important;
  .modal-wrapper {
    height: 60vh !important;
    position: absolute !important;
    bottom: 0 !important;
  }
}

//general css rules
formly-field {
  .custom-checkbox,
  .custom-label,
  .select,
  .custom-general-input {
    width: 100%;
  }
  .label-stacked {
    margin-bottom: 6px;
  }
  .item-toggle {
    ion-label {
      white-space: pre-wrap; /* CSS3 */
      white-space: -moz-pre-wrap; /* Firefox */
      white-space: -pre-wrap; /* Opera <7 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word;
    }
  }
  input {
    height: 2.5em;
  }
  // ion-input {
  //   border-bottom: 1px solid lightgray;
  //   background: var(--ion-card-background);
  //   padding-left: 6px;
  //   font-size: 90%;
  // }
  margin-bottom: 10px;
}

.multiple-select-alert {
  .alert-checkbox {
    .alert-checkbox-label {
      white-space: pre-wrap; /* CSS3 */
      white-space: -moz-pre-wrap; /* Firefox */
      white-space: -pre-wrap; /* Opera <7 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word;
      padding: 5px 0;
    }
  }
}

.ionic-selectable-item > .item-inner > .input-wrapper > .label {
  white-space: normal !important;
}

.ion-checkbox > .label {
  white-space: normal !important;
}

//map marker
.map-marker {
  width: 2rem;
  height: 2rem;
  border-radius: 50%; // 50% 50% 0;
  display: flex;
  background: var(--ion-color-primary);
  position: absolute;
  // transform: rotate(-45deg);
  align-items: center;
  left: 50%;
  top: 50%;
  margin: -1rem;
  // border: 1px solid #ffffff;
  // transition: all 10s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 4s ease-in;
  &.user-marker {
    background: none;
  }
  &.inner-circle::before {
    content: "";
    background: #fff;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    // transform: translate(-50%, -50%);
  }

  &.selected {
    // background: var(--ion-color-secondary);
    // width: 3rem;
    // height: 3rem;
    // margin: -1.5rem 0 0 -1.5rem;
    transform: scale(1.5);
  }
}

.map-marker-container {
  span {
    position: absolute;
    left: 0;
    right: 0;
    margin: 10px auto;
    text-align: center;
    color: var(--ion-gray-step-200);
  }

  i,
  ion-icon {
    position: absolute;
    width: 22px;
    font-size: 17px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    color: var(--ion-gray-step-200);
  }
}

.ht-header-toolbar {
  --border-width: 0 !important;
  .buttons-first-slot {
    padding-left: 16px;
    ion-back-button {
      font-size: 80%;
    }
  }
}

.header-with-bg {
  position: absolute;
  top: -5%;
  right: -5%;
  z-index: -1;
  width: 70%;
  height: 40%;
  opacity: 50%;
}

.header-toolbar {
  ion-toolbar {
    --border-width: 0;
  }
}

.swiper-pagination-bullet {
  transition: all 0.3s ease-in;
  opacity: 0.8;
}
.swiper-pagination-bullet-active {
  width: 16px;
  border-radius: 100px;
  opacity: 1;
}

#weatherslides {
  height: fit-content;
  position: relative;
  overflow: visible;
  .weather-swiper {
    height: 100%;
    overflow: visible;
    width: 100%;
  }
  .swiper-slide {
    width: 100%;
    overflow: visible;
    padding: 0 24px;
    height: auto;
  }
  ion-card {
    width: 100%;
    height: 100%;
    display: flex;
    background: var(--ion-color-step-100);

    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: drop-shadow(0px 4px 10px rgba(9, 9, 9, 0.24));
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #0909093d;
    border-radius: 8px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    ion-row {
      height: 100%;
    }
    ion-col {
      height: 100%;
    }
  }
  .weather {
    width: 100%;
    padding: 0 0.5rem;
    .main-temp {
      font-size: 2rem;
    }
    .side-data {
      font-size: 80%;
    }
  }

  .weather-data {
    display: flex;
    font-size: 90%;
    line-height: 90%;
    height: 100%;
    align-content: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: var(--ion-color-primary);
    ion-icon {
      margin-right: 0.5rem;
    }
    ion-row {
      width: 100%;
    }
  }
  .weather-swiper-pagination {
    width: 100%;
    text-align: center;
    bottom: 0;
    position: absolute;
    z-index: 999;
    .swiper-pagination-bullet {
      margin-right: 4px;
      background-color: var(--ion-color-step-400);
    }
    .swiper-pagination-bullet-active {
      width: 8px !important;
      border-radius: 100px;
      opacity: 1;
      background-color: var(--ion-color-primary);
    }
  }
  .forecast {
    width: 100%;
    display: flex;
    color: var(--ion-color-primary);
    font-size: 90%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
  }
}

//illustrations coloring rules
.il-bubble {
  transform-origin: center;
}
.il-color200 {
  fill: var(--ion-gray-step-200);
}
.il-color400 {
  fill: var(--ion-gray-step-400);
}
.il-color500 {
  fill: var(--ion-gray-step-500);
}
.il-color600 {
  fill: var(--ion-gray-step-600);
}
.il-color700 {
  fill: var(--ion-gray-step-700);
}
.il-color800 {
  fill: var(--ion-gray-step-800);
}
.il-color900 {
  fill: var(--ion-gray-step-900);
}
.il-color-primary {
  fill: var(--ion-color-primary);
}
.il-background {
  fill: var(--ion-gray-step-300);
}

ion-title {
  font-family: AppFont;
  font-size: 22px;
  line-height: 26px;
}
//Typography
h1 {
  font-family: AppFontMedium !important;
  font-size: 22px;
  line-height: 26px;
}
h2 {
  font-family: AppFont !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}
.title {
  font-family: AppFontMedium;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}
.subtitle {
  font-family: AppFontLight;
  font-size: 16px;
  line-height: 20px;
}
.large-button {
  font-family: AppFontMedium;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: var(--ion-color-primary);
}
.link {
  font-family: AppFontMedium;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.link .pressed {
  font-family: AppFontMedium;
  font-size: 16px;
  line-height: 20px;
}

.label {
  font-family: AppFont;
  font-size: 12px;
  line-height: 18px;
}

.caption {
  font-family: AppFontMedium;
  font-size: 10px;
  line-height: 12px;
}

.weather-font {
  font-family: AppFontLight;
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  color: var(--ion-gray-step-800);
}

.bg-gray-300 {
  background: var(--ion-gray-step-300);
}

.bg-gray-400 {
  background: var(--ion-gray-step-400);
}

.bg-gray-500 {
  background: var(--ion-gray-step-500);
}

.wave-color {
  fill: var(--ion-color-step-50) !important;
}
ion-input {
  --background: var(--ion-gray-step-200) !important;
  --color: var(--ion-gray-step-200);
  text-align: left;
  --padding-start: 1em;
  border: none;
  box-shadow: inset 0px -1px 0px var(--ion-gray-step-500);
  border-radius: 4px 4px 0px 0px;
  input {
    padding-inline-start: 1em !important;
    padding-inline-end: 1em !important;
  }
}

ion-button {
  font-family: AppFont;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: none;
  --border-width: 1px;
  --border-radius: 8px;
  --box-shadow: none;
  --ripple-color: var(--ion-gray-step-800);
  &.button-outline {
    text-transform: none;
  }
  ion-icon {
    margin: 10px;
    height: 14px;
  }
}

.hotel-slides {
  padding-left: 1em;
  .hotel-slide {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    .hotel-card {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(9, 9, 9, 0.24);
      margin: 0;

      .card-image {
        height: 50vh;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 8px;
        padding-top: 1em;
        text-align: left;
      }
    }
  }
}

.tag {
  display: flex;
  height: fit-content;
  width: fit-content;
  padding: 0 0.5em;
  border-radius: 0px 4px 4px 0px;
  background: var(--ion-gray-step-200);
}

.tab-icon-color {
  fill: var(--ion-gray-step-800);
}

.tab-selected {
  .tab-icon-color {
    fill: var(--ion-color-primary);
  }
}
.tabs-inner {
  position: unset !important;
}

.masonry-with-columns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ion-card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    background-color: rgba(var(--ion-color-primary-rgb), 0.1);
    filter: drop-shadow(0px 4px 10px rgba(9, 9, 9, 0.24));
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #0909093d;

    .multicard {
      position: absolute;
      right: -8px;
      top: 0;
      height: 100%;
      width: 8px;
      padding: 8px 0;
      justify-content: center;
      .extra-card {
        background: var(--ion-color-primary);
        width: 100%;
        height: 100%;
        border-radius: 0 8px 8px 0;
      }
    }
    &.multiple {
      width: calc(50% - 1em - 8px) !important;
      margin-right: 8px !important;
      overflow: visible;
      display: flex;
    }
    svg {
      margin: auto;
    }

    &.ion-activated {
      filter: drop-shadow(0px 2px 6px rgba(9, 9, 9, 0.24));
      box-shadow: 0px 4px 10px 0px #0909093d;
    }

    .page-title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 66%;
      display: flex;
      border-radius: 0 0 8px 8px;
      padding: 8px 16px;
      background: linear-gradient(
        180deg,
        rgba(9, 9, 9, 0) 16.03%,
        rgba(9, 9, 9, 0.8) 73.91%,
        #090909 100%
      );
      flex-direction: column;
      justify-content: flex-end;
      .page-caption {
        margin: auto auto 0 auto;
        text-align: center;
        color: white;
        width: 100%;
      }
      .activity-date {
        width: 100%;
        margin: 0;
        color: var(--ion-gray-step-500);
      }
      .activity-more {
        width: 100%;
        margin: 0;
        color: var(--ion-gray-step-500);
      }
    }

    &.weather {
      background: none;
      box-shadow: none;
      display: flex;
      padding: 0;
      flex-direction: column;
      justify-content: flex-end;
      .weather-container {
        background: rgba(var(--ion-color-primary-rgb), 0.1);
        height: 60%;
        width: 100%;
        margin-bottom: 0;
        border-radius: 8px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: flex-end;
        .temperature {
          margin: 0 auto;
          position: relative;
          .temperature-icon {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%);
          }
        }
        .weather-background {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);

          height: 10vh;
          width: 10vh;
          border-radius: 50%;
          display: flex;
          padding: 15px;
          &.menu_icon-weather-01d,
          &.menu_icon-weather-01n {
            background: #f9ad1c;
            color: var(--ion-gray-step-800);
          }
          &.menu_icon-weather-02d,
          &.menu_icon-weather-02n {
            background: #fcdf66;
            color: var(--ion-gray-step-800);
          }
          &.menu_icon-weather-03 {
            background: #ced1de;
            color: var(--ion-gray-step-800);
          }
          &.menu_icon-weather-04 {
            background: #7f9ba6;
            color: var(--ion-gray-step-200);
          }
          &.menu_icon-weather-09 {
            background: #606573;
            color: var(--ion-gray-step-200);
          }
          &.menu_icon-weather-10 {
            background: #48515c;
            background-color: #48515c;
            color: var(--ion-gray-step-200);
          }
          &.menu_icon-weather-11 {
            background: #001433;
            color: var(--ion-gray-step-200);
          }
          &.menu_icon-weather-12 {
            background: #2daad8;
            color: var(--ion-gray-step-200);
          }
          &.menu_icon-weather-12 {
            background: #90b8cf;
            color: var(--ion-gray-step-200);
          }
          ion-icon {
            margin: auto;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &.icon-page {
      .page-title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        background: white;
        padding: 0.5em;
        .page-caption {
          margin: auto auto 0 auto;
          text-align: center;
          color: var(--ion-color-primary);
        }
      }
    }
  }
  &.service-page {
    padding: 0;
    ion-col {
      padding: 8px;
    }
    ion-card {
      margin: 0;
      flex-direction: column;
      padding: 0;
      .page-title {
        position: relative;
        width: 100%;
      }
    }

    .icon-container {
      padding: 16px;
    }
  }
  .masonry-group-4 {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 50vh;
    align-items: center;
    ion-card {
      flex: 1 0 auto;
      color: white;
      // margin: 0 1rem 1rem 0;
      margin: 0;
      margin-bottom: 1em;
      width: calc(50% - 1em);
    }
    .page:first-child:nth-last-child(4),
    .page:first-child:nth-last-child(4) ~ .page {
      &:nth-of-type(1) {
        height: calc(60% - 1em);
      }
      &:nth-of-type(2) {
        height: calc(40% - 1em);
      }
      &:nth-of-type(3) {
        height: calc(40% - 1em);
      }
      &:nth-of-type(4) {
        height: calc(60% - 1em);
      }
    }

    .activity:first-child:nth-last-child(4),
    .activity:first-child:nth-last-child(4) ~ .activity {
      &:nth-of-type(1) {
        height: calc(40% - 1em);
      }
      &:nth-of-type(2) {
        height: calc(60% - 1em);
      }
      &:nth-of-type(3) {
        height: calc(60% - 1em);
      }
      &:nth-of-type(4) {
        height: calc(40% - 1em);
      }
    }
  }
  .masonry-group-3 {
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 40vh;
    align-items: center;
    align-content: flex-start;
    ion-card {
      flex: auto;

      color: white;
      margin: 0 0.5em;
      width: calc(50% - 2em);
    }
    ion-card:nth-of-type(1) {
      height: calc(50% - 1em);
    }
    ion-card:nth-of-type(2) {
      height: calc(50% - 1em);
    }
    ion-card:nth-of-type(3) {
      height: calc(50% - 1em);
      margin-top: 1em;
    }
  }
  .masonry-group-2 {
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 20vh;
    align-items: center;
    align-content: flex-start;
    ion-card {
      flex: auto;

      color: white;
      margin: 0 0.5em;
      width: calc(50% - 2em);
      height: 100%;
    }
    // ion-card:nth-of-type(1) {
    //   height: calc(50% - 1em);
    // }
    // ion-card:nth-of-type(2) {
    //   height: calc(50% - 1em);
    // }
  }
  .masonry-group-1 {
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 20vh;
    align-items: center;
    align-content: flex-start;
    ion-card {
      flex: auto;

      color: white;
      margin: 0 0.5em;
      margin-top: 1em;
      width: calc(100% - 1em);
      height: 100%;
    }
  }
}

.main-content {
  padding: 24px;
  background: var(--ion-background-color);
  .page-label {
    margin-top: 16px;
  }
  .description {
    padding-top: 8px;
  }
  .list {
    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      padding-left: 24px;
    }
  }
  .section {
    background: var(--ion-gray-step-300);
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
    .section-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      svg {
        align-self: center;
        margin-right: 14px;
        transition-property: transform;
        transition-duration: 0.2s;
        transition-delay: 0.1s;
      }

      .animate-chev {
        transform: scale(1, -1);
      }
    }
    .section-content {
      ul {
        padding-inline-start: 24px !important;
      }
    }
  }
}
.page-cards-container {
  &.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    .page-card {
      height: auto !important;
      aspect-ratio: 1 !important;
      width: 45% !important;
      margin: 10px 0;
    }
  }
  .page-card {
    height: 15vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: space-between;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: drop-shadow(0px 4px 10px rgba(9, 9, 9, 0.24));
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #0909093d;
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;

    &.icon-page {
      .hotel-icon {
        margin: auto;
      }
      .page-title {
        width: 100%;
        height: auto;
        display: flex;
        background: white;
        padding: 0.5em;
        .page-caption {
          margin: auto auto 0 auto;
          text-align: center;
          color: var(--ion-color-primary);
        }
      }
    }
    &.ion-activated {
      filter: drop-shadow(0px 2px 6px rgba(9, 9, 9, 0.24));
      box-shadow: 0px 4px 10px 0px #0909093d;
    }

    .page-title {
      width: 100%;
      height: 90%;
      padding: 0.5em;
      display: flex;
      background: linear-gradient(
        180deg,
        rgba(9, 9, 9, 0) 16.03%,
        rgba(9, 9, 9, 0.8) 73.91%,
        #090909 100%
      );
      .page-caption {
        margin: auto auto 0 auto;
        text-align: center;
        color: white;
      }

      .page-date {
        width: 100%;
        margin: 0;
        color: var(--ion-gray-step-500);
      }
      .page-more {
        width: 100%;
        margin: 0;
        color: var(--ion-gray-step-500);
      }
    }
    .page-logo {
      margin: auto;
      padding: 1em;
      display: flex;
      &.list-logo {
        height: 60%;
      }
      &.logo-with-title {
        height: 65%;
      }
    }
    .page-logo-background {
      width: 100%;
      height: 100%;
      &.darker-background {
        background-color: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }
}
.page-gallery-container {
  margin-top: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;

  .gallery-photo {
    width: 100%;
    height: 10vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.page-footer-bar {
  ion-button {
    margin: 0 24px;
  }
}

.main-page-header {
  --border-width: 0;
  ion-toolbar {
    --padding-top: var(--ion-statusbar-padding);
    padding-left: 8px;
    padding-right: 8px;
  }
  ion-button,
  ion-back-button,
  ion-menu-button {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    --color: var(--ion-gray-step-900);
    --background: rgba(255, 255, 255, 0.7);
    --border-radius: 8px;
  }
  h2 {
    margin: auto;
  }
}

.promotion-slides {
  padding: 0 24px;
  margin: 24px 0;
  overflow: visible;
  height: 14vh;
  .swiper-pagination-bullets {
    bottom: -1.5vh;
  }
  .swiper-pagination-bullet {
    background-color: var(--ion-color-step-400);
  }
  .swiper-pagination-bullet-active {
    border-radius: 100px;
    opacity: 1;
    background-color: var(--ion-color-primary);
  }
}

.card-slide {
  width: 100%;
  .page-card {
    height: 100%;
    width: 100%;
    display: flex;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: drop-shadow(0px 4px 10px rgba(9, 9, 9, 0.24));
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #0909093d;
    border-radius: 8px;
    &.ion-activated {
      filter: drop-shadow(0px 2px 6px rgba(9, 9, 9, 0.24));
      box-shadow: 0px 4px 10px 0px #0909093d;
    }
    .page-title {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: -1px;
      width: 100%;
      height: 90%;
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: left;

      background: linear-gradient(
        180deg,
        rgba(9, 9, 9, 0) 16.03%,
        rgba(9, 9, 9, 0.8) 73.91%,
        #090909 100%
      );
      .page-caption {
        // margin: 10px 16px;
        text-align: left;
        color: white;
      }
      .page-date {
        width: 100%;
        margin: 0;
        color: var(--ion-gray-step-500);
      }
      .page-more {
        width: 100%;
        margin: 0;
        color: var(--ion-gray-step-500);
      }
    }
  }
}
.item-extras-modal {
  --backdrop-opacity: 0.8;
  border-radius: 16px 16px 0px 0px;

  .modal-wrapper {
    overflow: visible;
    // box-shadow: 0px 0px 12px rgba(9, 9, 9, 0.16);
    border-radius: 16px 16px 0px 0px;
    height: 40vh;
    bottom: 0;
    position: absolute;
    app-item-extras {
      overflow: visible;
    }
    ion-fab {
      transform: translateY(-110%);
    }
    ion-header {
      border-width: 0;
      border-radius: 16px 16px 0px 0px;
      ion-item {
        border-radius: 16px 16px 0px 0px;
      }
    }
  }
}

.poi-map-filter-modal {
  --backdrop-opacity: 0.8;
  .modal-wrapper {
    box-shadow: 0px 0px 12px rgba(9, 9, 9, 0.16);
    border-radius: 16px 16px 0px 0px;
    // height: 40vh;
    height: 70vh;
    bottom: 0;
    position: absolute;
    .filter-content {
      --background: var(--ion-gray-step-300);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .handle-container {
        padding: 3vw 0 4vw 0;
        display: flex;
        align-items: center;
        width: 100%;
        background: var(--ion-gray-step-200);

        .handle {
          width: 40px;
          height: 4px;
          border-radius: 1px;
          color: var(--ion-gray-step-500);
          background-color: var(--ion-gray-step-500);
          margin: 10px auto;
          padding: 0;
        }
      }

      .search-container {
        background: var(--ion-gray-step-200);
        .title {
          padding: 0 24px;
        }
        ion-searchbar {
          padding: 10px 24px 24px 24px;
          --background: var(--ion-gray-step-200);
          .searchbar-input-container {
            border: 1px solid var(--ion-gray-step-500);
          }
        }
      }
    }

    .modal-footer {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.order-overview-modal {
  .modal-wrapper {
    box-shadow: 0px 0px 12px rgba(9, 9, 9, 0.16);
    border-radius: 16px 16px 0px 0px;
    height: 80vh;
    bottom: 0;
    position: absolute;
  }
}

.order-overview-modal-mini {
  .modal-wrapper {
    box-shadow: 0px 0px 12px rgba(9, 9, 9, 0.16);
    border-radius: 16px 16px 0px 0px;
    height: 40vh;
    bottom: 0;
    position: absolute;
  }
}

.order-menu-modal {
  --backdrop-opacity: 0.8;
  .modal-wrapper {
    box-shadow: 0px 0px 12px rgba(9, 9, 9, 0.16);
    border-radius: 16px 16px 0px 0px;
    // height: 40vh;
    height: 80vh;
    bottom: 0;
    position: absolute;
    .order-modal-content {
      --background: var(--ion-gray-step-300);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // .handle {
      //   width: 40px;
      //   height: 4px;
      //   border-radius: 1px;
      //   color: var(--ion-gray-step-500);
      //   background-color: var(--ion-gray-step-500);
      //   margin: 0 auto;
      //   padding: 0;
      // }
      .modal-content {
        padding: 0 8px;

        .modal-item {
          --background: none;
          ion-textarea {
            background: var(--ion-gray-step-200);
            width: 100%;
            box-shadow: inset 0px -1px 0px var(--ion-gray-step-500);
            border-radius: 4px 4px 0px 0px;
            padding: 12px 16px;
          }
        }
        .extra {
          margin-left: 8px;
          margin-right: 16px;
          margin-bottom: 8px;
          border-radius: 8px;
        }
        .options-container {
          display: flex;
        }
        .description {
          margin-top: 8px;
        }
        .label {
          color: var(--ion-gray-step-800);
        }
        .char-counter {
          width: 100%;
          text-align: right;
          padding: 0 16px;
        }
        .edit-button {
          height: 40px;
          width: 40px;
          --padding-start: 10px;
          --padding-end: 10px;
          --border-radius: 8px;
          margin: auto;
          margin-top: 0;
          ion-icon {
            margin: 0;
            height: 40px;
          }
        }
      }
    }
    .order-modal-footer {
      --background: var(--ion-gray-step-300);
      ion-toolbar {
        --background: var(--ion-gray-step-300);
        .edit-button {
          height: 40px;
          width: 40px;
          --padding-start: 10px;
          --padding-end: 10px;
          --border-radius: 8px;
          margin: auto;
          ion-icon {
            margin: 0;
            height: 40px;
          }
        }
        .item-quantity {
          display: flex;
          h1 {
            margin: auto;
          }
        }
        .add-button {
          height: 40px;
        }
      }
    }
  }

  &.no-image-menu {
    .modal-wrapper {
      height: 50vh;
    }
  }
}
app-custom-general-input {
  --background: none;
  --padding-start: 0;
  --padding-end: 0;
  .custom-general-input {
    ion-item {
      --background: none;
      background: none;
      --padding-start: 0;
      --padding-end: 0;
      border-top: 1px solid var(--ion-color-danger);
      --color: var(--ion-color-danger);
    }
    // ion-item::part(native) {
    //   box-shadow: inset 0px -1px 0px var(--ion-gray-step-500) !important;
    //   --box-shadow: inset 0px -1px 0px var(--ion-gray-step-500) !important;
    // }
    .label {
      color: var(--ion-gray-step-700);
    }
    input {
      border: none;
      position: relative;
    }
    .info-icon {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      // padding-right: 8px;
      height: 100%;
      z-index: 100;
      .tooltip-button {
        margin: auto;
        ion-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

app-tooltip-message {
  background: var(--ion-gray-step-900);
  color: var(--ion-gray-step-200);
  padding: 6px 12px;
  border-radius: 4px;
  .popover-arrow::after {
    background: var(--ion-gray-step-900) !important;
  }
}
.popover-arrow::after {
  background: var(--ion-gray-step-300) !important;
}

@supports (backdrop-filter: blur(0)) {
  :host(.popover-translucent) .popover-content,
  :host(.popover-translucent) .popover-arrow::after {
    background: var(--ion-gray-step-900) !important;
    backdrop-filter: saturate(180%) blur(20px);
  }
}

.text-area-item {
  --background: none;
  --inner-padding-end: 0;
  padding-right: 0;
  .label {
    color: var(--ion-gray-step-700);
  }
  ion-textarea {
    background: var(--ion-gray-step-200);
    width: 100%;
    box-shadow: inset 0px -1px 0px var(--ion-gray-step-500);
    border-radius: 4px 4px 0px 0px;
    padding: 12px 16px;
  }
  .char-counter {
    width: 100%;
    text-align: right;
    color: var(--ion-gray-step-700);
  }
}

.general-separator {
  border-top: 1px solid var(--ion-gray-step-500);
  margin: 15px 0 16px 0;
}

app-custom-radio-inline,
app-custom-textarea {
  ion-item::part(native) {
    .item-inner {
      padding: 0;
      padding-inline-end: 0;
    }
  }
}

.success-request-popover {
  --width: 60%;
  --box-shadow: 0px 4px 10px 0px #0909093d;
  --backdrop-opacity: 0.6;
  text-align: center;
  .request-confirmation {
    width: 100%;
    padding: 24px;
    text-align: center;
    background: var(--ion-gray-step-200);
    .success-request-illustration {
      width: 100%;
      height: auto;
      overflow: visible;
      padding: 24px;
    }
    .check-icon {
      opacity: 0;
      transition: opacity 0.5 ease-in;
    }
    h2 {
      color: var(--ion-color-primary);
    }
  }
}

.activity-alert-popover {
  --width: 70%;
  --height: 70vw;
  --box-shadow: 0px 4px 10px 0px #0909093d;
  --backdrop-opacity: 0.6;

  .popover-content {
    --background: white;
    background: white !important;
  }
  app-activity-alert {
    border-radius: 20px;
  }
}

app-reservation {
  background: var(--ion-gray-step-200);
}

.status-container {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  div {
    &[class*="chip-"] {
      margin: 0;
      padding: 2px 8px;
      border-radius: 4px;
      &[class*="pending"] {
        background: var(--ion-gray-step-500);
        color: var(--ion-gray-step-800);
      }
      &[class*="accepted"] {
        background: var(--ion-gray-step-500);
        color: var(--ion-gray-step-800);
      }
      &[class*="pending"] {
        background: var(--ion-gray-step-500);
        color: var(--ion-gray-step-800);
      }
    }
  }
}

.feedback-button {
  border-radius: 8px;
  --background: var(--ion-gray-step-300);
}

#rating_1,
#rating_2 {
  border-bottom: 1px solid var(--ion-gray-step-500) !important;
  margin-bottom: 24px !important;
  padding-bottom: 8px !important;
}

.nps-confirm-dialog {
  --background: var(--ion-gray-step-300);
  .alert-input {
    background: var(--ion-gray-step-200);
  }
  .popover-content {
    overflow: visible;
    border-radius: 8px;
  }
  .rate-container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0;
    left: 50%;
    background: var(--ion-gray-step-300);
    border-radius: 50%;
    padding: 4px;
  }
}

.nps-follow-up-popover {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);
  ion-backdrop {
    z-index: -1;
  }
}

.contact-list-modal {
  --height: 50%;
  --bottom: 0;
  .modal-wrapper {
    box-shadow: 0px 0px 12px rgba(9, 9, 9, 0.16);
    border-radius: 16px 16px 0px 0px;
    bottom: 0;
    position: absolute;
    .contact-modal-content {
      --background: var(--ion-gray-step-300);
      .handle {
        width: 40px;
        height: 4px;
        border-radius: 1px;
        color: var(--ion-gray-step-500);
        background-color: var(--ion-gray-step-500);
        margin: 0 auto;
        padding: 0;
      }
      .modal-content {
        padding: 0 8px;
      }
    }
  }
}

.chip-accepted {
  color: var(--ion-color-success) !important;
  background: var(--ion-color-success-tint) !important;
}
.chip-rejected {
  color: var(--ion-color-danger) !important;
  background: var(--ion-color-danger-tint) !important;
}
.chip-pending {
  color: var(--ion-color-disabled) !important;
  background: var(--ion-color-disabled-tint) !important;
}

.ht-tab-section {
  .section-title {
    padding: 8px 24px 0 24px;
  }

  .request-card-list {
    padding: 8px 16px;
    .request-card {
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(9, 9, 9, 0.24);
      margin-bottom: 16px;

      ion-item {
        --padding: 16px;
        .card-icon {
          margin-inline-end: 16px;
          background: rgba(var(--ion-color-primary-rgb), 0.24);
          padding: 13px 10px;
          border-radius: 8px;
          margin-top: 16px;
          margin-bottom: 16px;
          margin-left: 16px;
          height: 50px;
          min-height: 50px;
          max-height: 50px;
        }

        ion-icon {
          width: 24px;
          height: 24px;
          font-size: 24px !important;
          line-height: 24px !important;
        }

        &.reservation-card {
          --padding-start: 0;
        }

        .card-image {
          width: 72px;
          height: 78px;
          margin-inline-end: 16px;

          border-radius: 8px 0px 0px 8px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}

.spot-select {
  margin-top: 16px;

  .label {
    width: 100%;
    color: var(--ion-gray-step-700);
    margin-bottom: 8px;
  }
  ion-select {
    width: 100%;
    padding-left: 8px;
    background: var(--ion-gray-step-200);
    box-shadow: inset 0px -1px 0px var(--ion-gray-step-500) !important;
  }
}

@function property-value($property, $selector: null) {
  @return $magic;
}
.promotion-popover {
  --width: 80%;
  --max-height: 80%;
  --box-shadow: 0px 4px 10px 0px #0909093d;
  --backdrop-opacity: 0.6;
  border-radius: 8px;

  .popover-content {
    // top: 25% !important;
    // top: calc(
    //   100vh - property-value(height, ".promotion-popover") / 2
    // ) !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    .promotion-content {
      width: 100%;
      background: var(--ion-background-color);
      overflow-y: scroll;
      .main-content {
        padding: 24px;

        .description {
          padding-top: 8px;
        }
      }
    }

    ion-footer {
      padding: 8px;
      background: var(--ion-background-color);
    }
  }
}
.phoneNum {
  background: none !important;
  border: none !important;
}
input#country-search-box {
  background: white;
}
formly-group.row {
  margin: 0;
}
.booking-action-sheet {
  .action-sheet-wrapper {
    .action-sheet-container {
      .action-sheet-group {
        background: var(--ion-color-step-50);
        .action-sheet-title {
          text-align: center;
          color: var(--ion-color-primary);
          font-size: 180%;
          border-bottom: 1px solid var(--ion-color-step-500);
          background: var(--background);
          .action-sheet-sub-title {
            font-size: 70%;
          }
        }
      }
    }
  }
}
.hotel-logo {
  margin: 0;
  width: 35%;
  z-index: 10;
}
.custom-select-search {
  background: var(--ion-gray-step-200);
}
app-page-group {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  padding-bottom: 44px;
  height: 100%;
}

.quick-action-slides {
  overflow: visible;
  .qa-slide {
    flex-shrink: 1 !important;
  }
}

.event-description p,
.event-description div {
  display: inline !important;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: var(--ion-color-primary) !important;
}
.mat-slider-thumb-label-text {
  font-size: 22px !important;
}
.mat-slider:not(.mat-slider-disabled).cdk-focused .mat-slider-thumb-label {
  width: 50px;
  height: 50px;
  left: -70px;
  bottom: -25px;
}
.smart-room-modal {
  --max-height: 90%;
  .modal-wrapper {
    position: absolute;
    bottom: 0;
    border-radius: 20px 20px 0 0;
  }
}
.smart-control-popover {
  --width: 90%;
  ion-backdrop {
    opacity: 0.7;
  }
  .popover-wrapper {
    display: flex;
    position: relative;
    width: 90%;
    height: fit-content;
    top: 0;
    left: 0;
    .popover-content {
      position: relative;
      margin: auto;
      top: 0 !important;
      left: 0 !important;
    }
  }
}

.choose-app-alert-popover {
  --width: 100%;
  --backdrop-opacity: 0.7;
  // ion-backdrop {
  //   opacity: 0.7;
  // }
  .popover-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: fit-content;
    bottom: 0;
    left: 0;
    .popover-content {
      bottom: 0 !important;
      top: 0 !important;
      left: 0 !important;
      position: relative;
      margin: auto;
      text-align: center;
    }
  }
}

::ng-deep table {
  color: var(--ion-text-color) !important;
}
