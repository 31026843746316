// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/Roboto-Regular.ttf"); //change url accordingly
}

@font-face {
  font-family: AppFontLight;
  src: url("../assets/fonts/Roboto-Light.ttf"); //change url accordingly
}
@font-face {
  font-family: AppFontMedium;
  src: url("../assets/fonts/Roboto-Medium.ttf"); //change url accordingly
}

// @font-face {
//   font-family: EBGaramond;
//   src: url("../assets/fonts/EBGaramond-VariableFont_wght.ttf"); //change url accordingly
// }

// @font-face {
//   font-family: Noto;
//   src: url("../assets/fonts/NotoSans-Regular.ttf"); //change url accordingly
// }

/** Ionic CSS Variables **/

:root {
  body {
    background: none;
    font-size: 14px;
    line-height: 20px;
    // color: var(--ion-gray-step-900);
    &.garaMondFont {
      --ion-font-family: EBGaramond, sans-serif;
    }

    &.notoFont {
      --ion-font-family: Noto, sans-serif;
    }
  }

  --ion-font-family: AppFont, sans-serif;
  // --ion-color-primary: linear-gradient(166deg, #22c1c3 0%, #2da4fd 100%);//#062A4A;

  // --ion-color-primary: #5c3d46;
  // --ion-color-primary-rgb: 92, 61, 70;

  // --ion-color-primary: #5c868d;
  // --ion-color-primary-rgb: 92, 134, 141;

  // --ion-color-primary: #4f728e;
  // --ion-color-primary-rgb: 79, 114, 142;

  --ion-color-primary: #7d6855;
  --ion-color-primary-rgb: 125, 104, 85;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #6e5c4b;
  --ion-color-primary-tint: #8a7766;

  --ion-color-secondary: #0575e6;
  --ion-color-secondary-rgb: 0, 76, 129;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #004372;
  --ion-color-secondary-tint: #1a5e8e;

  --ion-color-tertiary: #1c588e;
  --ion-color-tertiary-rgb: 28, 88, 142;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #194d7d;
  --ion-color-tertiary-tint: #336999;

  --ion-color-success: #117c00;
  --ion-color-success-rgb: 17, 124, 0;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #d7ffd1;
  --ion-color-success-tint: #d7ffd1;

  --ion-color-warning: #f29124;
  --ion-color-warning-rgb: 242, 145, 36;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d58020;
  --ion-color-warning-tint: #f39c3a;

  --ion-color-danger: #cc0027;
  --ion-color-danger-rgb: 166, 22, 30;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ffdde3;
  --ion-color-danger-tint: #ffdde3;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #3a3a3a;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #525252;
  --ion-text-color-rgb: 82, 82, 82;

  --ion-messages-indicator: #f08356;
  --ion-messages-verification: #29bf12;
  --ion-messages-verification-bg: #e7fce4;
  --ion-messages-error: #e50e37;
  --ion-messages-error-bg: #fbe6ea;
  --ion-messages-warning: #ff7101;
  --ion-messages-warning-bg: #7ca6ff;
  --ion-messages-disabled: #555555;
  --ion-messages-disabled-bg: #808080;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;

  --ion-gray-step-200: #ffffff;
  --ion-gray-step-300: #f6f6f6;
  --ion-gray-step-400: #eeeeee;
  --ion-gray-step-500: #dddddd;
  --ion-gray-step-600: #aaaaaa;
  --ion-gray-step-700: #888888;
  --ion-gray-step-800: #555555;
  --ion-gray-step-900: #090909;

  --ion-gray-step-rgb-200: 255, 255, 255;
  --ion-gray-step-rgb-300: 246, 246, 246;
  --ion-gray-step-rgb-400: 238, 238, 238;
  --ion-gray-step-rgb-500: 221, 221, 221;
  --ion-gray-step-rgb-600: 170, 170, 170;
  --ion-gray-step-rgb-700: 136, 136, 136;
  --ion-gray-step-rgb-800: 85, 85, 85;
  --ion-gray-step-rgb-900: 9, 9, 9;

  --ion-toolbar-background: #ffffff;

  --ion-item-background: #e5e5e5;

  --ion-card-background: #fbfbfb;

  --ion-text-color: #090909; //ion-gray-step-900
  --ion-background-color: #ffffff; //ion-gray-step-300
  --ion-background-color-rgb: 246, 246, 246;
  --ripple-color: var(--ion-gray-step-800) 40%;

  body.dark,
  .ios body.dark,
  .md body.dark {
    /** primary **/

    --ion-font-family: AppFontDark, sans-serif;

    --ion-color-primary: #b1a378;
    --ion-color-primary-rgb: 177, 163, 120;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #9c8f6a;
    --ion-color-primary-tint: #b9ac86;

    /** secondary **/
    --ion-color-secondary: #0575e6;
    --ion-color-secondary-rgb: 226, 129, 77;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #c77244;
    --ion-color-secondary-tint: #e58e5f;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #111111;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-text-color: var(--ion-gray-step-900); //ion-gray-step-900

    --ion-background-color: #222222;
    --ion-background-color-rgb: 34, 34, 34;

    --ion-toolbar-background: var(--ion-background-color);

    --ion-item-background: #3a3a3a;

    --ion-card-background: #3a3a3a;

    --ripple-color: var(--ion-gray-step-800) 40%;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-gray-step-200: #090909;
    --ion-gray-step-300: #555555;
    --ion-gray-step-400: #888888;
    --ion-gray-step-500: #aaaaaa;
    --ion-gray-step-600: #dddddd;
    --ion-gray-step-700: #eeeeee;
    --ion-gray-step-800: #f6f6f6;
    --ion-gray-step-900: #ffffff;

    --ion-gray-step-rgb-200: 9, 9, 9;
    --ion-gray-step-rgb-300: 85, 85, 85;
    --ion-gray-step-rgb-400: 136, 136, 136;
    --ion-gray-step-rgb-500: 170, 170, 170;
    --ion-gray-step-rgb-600: 221, 221, 221;
    --ion-gray-step-rgb-700: 238, 238, 238;
    --ion-gray-step-rgb-800: 246, 246, 246;
    --ion-gray-step-rgb-900: 255, 255, 255;

    //activities dark theme
    .timeline-badge {
      margin: auto;
      z-index: 10;
      background: var(--ion-background-color);
      color: var(--ion-color-primary);
      border: 1px solid var(--ion-primary-color);
      box-shadow: 0px 0 5px 0px var(--ion-color-secondary-tint);
    }
    .timeline-line-container {
      height: 100%;
      width: 100%;
      display: flex;
      position: absolute;
    }
    .timeline-line {
      width: 2px;
      height: 100%;
      margin: auto;
      transform: translateX(-50%);
      z-index: 1;
      background: var(--ion-color-primary);
      box-shadow: 2px 0 5px 0px black;
    }
    .timeline-card {
      border-radius: 20px;
      box-shadow: none !important;
    }
    .subpage-container {
      .subpage {
        box-shadow: none !important;
      }
    }
    .gray-section {
      .background {
        background: var(--ion-color-step-200) !important;
        .map {
          background-image: url("../assets/imgs/icons-background-map-dark.svg") !important;
        }
        .gradient-overlay {
          background: linear-gradient(
            270deg,
            rgba(var(--ion-gray-step-rgb-300), 0) 0%,
            rgba(var(--ion-gray-step-rgb-300), 0.4) 44.27%,
            var(--ion-gray-step-300) 100%
          ) !important;
        }
      }
      .ui-container {
        .categories {
          background: rgba(var(--ion-gray-step-rgb-500), 0.1) !important;
        }
      }
    }
    .quick-action-slider {
      ion-button {
        --background: #000 !important;
        --color: var(--ion-gray-color-900) !important;
      }
    }
    .map-button {
      ion-fab-button {
        --background: #000 !important;
        --color: var(--ion-gray-color-900) !important;
      }
    }
    .page-label {
      color: var(--ion-color-primary) !important;
    }
    ion-tab-bar {
      box-shadow: 0px 0px 10px 2px var(--ion-background-color) !important;
    }
    .feedback-segment {
      .choice {
        .icon-background {
          fill: var(--ion-gray-step-400) !important;
        }
        .icon-foreground {
          fill: var(--ion-gray-step-700);
        }
        &.segment-button-checked {
          // background: rgba(var(--ion-color-primary-rgb), 0.1);
          .icon-background {
            fill: var(--ion-gray-step-600) !important;
          }
          .icon-foreground {
            fill: var(--ion-color-primary);
          }
        }
      }
    }
    ion-back-button {
      --background: rgba(var(--ion-background-color-rgb), 0.7) !important;
    }

    .main-content {
      .section {
        background: var(--ion-color-step-100) !important;
      }
    }

    .icon-page {
      background-color: rgba(var(--ion-gray-step-rgb-300), 0.2) !important;
      .page-title {
        background: rgba(var(--ion-gray-step-rgb-300), 0.5) !important;
      }
    }
    .il-background {
      fill: #0b0701 !important;
    }
    .il-color500 {
      fill: rgba(var(--ion-color-primary-rgb), 0.5) !important;
    }
    .il-color-primary {
      // fill: var(--ion-gray-step-600) !important;
    }
    .button-slider {
      .section-button-slide {
        ion-card {
          background: #000 !important;
          color: var(--ion-gray-color-900) !important;
        }
      }
    }
    .help-button {
      background: rgba(var(--ion-gray-step-rgb-300), 0.2) !important;
      .subtitle {
        color: var(--ion-color-primary) !important;
      }
    }
    .profile-header {
      --background: var(--ion-color-step-100) !important;
    }
    .drawer-header {
      background: var(--ion-color-step-100) !important;
    }
    .profile-header {
      background: var(--ion-color-step-100) !important;
      ion-toolbar {
        --background: var(--ion-color-step-100) !important;
      }
    }
    .ui-container {
      .input-square {
        box-shadow: 0 0 0 100vmax var(--ion-background-color) !important;
        .input-square-instructions {
          background: var(--ion-background-color) !important;
        }
      }
      .separator {
        border-top: 1px solid var(--ion-color-primary) !important;
        .word {
          background: var(--ion-background-color) !important;
          color: var(--ion-color-primary) !important;
        }
      }
    }
    .main-page-header {
      ion-button,
      ion-back-button,
      ion-menu-button {
        --background: var(--ion-background-color);
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body.dark,
  .ios body.dark,
  .md body.dark {
    /** primary **/
    --ion-color-primary: #b1a378;
    --ion-color-primary-rgb: 177, 163, 120;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #9c8f6a;
    --ion-color-primary-tint: #b9ac86;

    /** secondary **/
    --ion-color-secondary: #0575e6;
    --ion-color-secondary-rgb: 226, 129, 77;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #c77244;
    --ion-color-secondary-tint: #e58e5f;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #111111;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-text-color: var(--ion-gray-step-900); //ion-gray-step-900

    --ion-background-color: #222222;
    --ion-background-color-rgb: 34, 34, 34;

    --ion-toolbar-background: var(--ion-background-color);

    --ion-item-background: #3a3a3a;

    --ion-card-background: #3a3a3a;

    --ripple-color: var(--ion-gray-step-800) 40%;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-gray-step-200: #090909;
    --ion-gray-step-300: #555555;
    --ion-gray-step-400: #888888;
    --ion-gray-step-500: #aaaaaa;
    --ion-gray-step-600: #dddddd;
    --ion-gray-step-700: #eeeeee;
    --ion-gray-step-800: #f6f6f6;
    --ion-gray-step-900: #ffffff;

    --ion-gray-step-rgb-200: 9, 9, 9;
    --ion-gray-step-rgb-300: 85, 85, 85;
    --ion-gray-step-rgb-400: 136, 136, 136;
    --ion-gray-step-rgb-500: 170, 170, 170;
    --ion-gray-step-rgb-600: 221, 221, 221;
    --ion-gray-step-rgb-700: 238, 238, 238;
    --ion-gray-step-rgb-800: 246, 246, 246;
    --ion-gray-step-rgb-900: 255, 255, 255;

    //activities dark theme
    .timeline-badge {
      margin: auto;
      z-index: 10;
      background: var(--ion-background-color);
      color: var(--ion-color-primary);
      border: 1px solid var(--ion-primary-color);
      box-shadow: 0px 0 5px 0px var(--ion-color-secondary-tint);
    }
    .timeline-line-container {
      height: 100%;
      width: 100%;
      display: flex;
      position: absolute;
    }
    .timeline-line {
      width: 2px;
      height: 100%;
      margin: auto;
      transform: translateX(-50%);
      z-index: 1;
      background: var(--ion-color-primary);
      box-shadow: 2px 0 5px 0px black;
    }
    .timeline-card {
      border-radius: 20px;
      box-shadow: none !important;
    }
    .subpage-container {
      .subpage {
        box-shadow: none !important;
      }
    }
    .gray-section {
      .background {
        background: var(--ion-color-step-200) !important;
        .map {
          background-image: url("../assets/imgs/icons-background-map-dark.svg") !important;
        }
        .gradient-overlay {
          background: linear-gradient(
            270deg,
            rgba(var(--ion-gray-step-rgb-300), 0) 0%,
            rgba(var(--ion-gray-step-rgb-300), 0.4) 44.27%,
            var(--ion-color-step-200) 100%
          ) !important;
        }
      }
      .ui-container {
        .categories {
          background: rgba(var(--ion-gray-step-rgb-500), 0.1) !important;
        }
      }
    }
    .quick-action-slider {
      ion-button {
        --background: #000 !important;
        --color: var(--ion-gray-color-900) !important;
      }
    }
    .map-button {
      ion-fab-button {
        --background: #000 !important;
        --color: var(--ion-gray-color-900) !important;
      }
    }
    .page-label {
      color: var(--ion-color-primary) !important;
    }
    ion-tab-bar {
      box-shadow: 0px 0px 10px 2px var(--ion-background-color) !important;
    }
    .feedback-segment {
      .choice {
        .icon-background {
          fill: var(--ion-gray-step-400) !important;
        }
        .icon-foreground {
          fill: var(--ion-gray-step-700);
        }
        &.segment-button-checked {
          // background: rgba(var(--ion-color-primary-rgb), 0.1);
          .icon-background {
            fill: var(--ion-gray-step-600) !important;
          }
          .icon-foreground {
            fill: var(--ion-color-primary);
          }
        }
      }
    }
    ion-back-button {
      --background: rgba(var(--ion-background-color-rgb), 0.7) !important;
    }

    .main-content {
      .section {
        background: rgba(var(--ion-gray-step-rgb-300), 0.2) !important;
      }
    }

    .icon-page {
      background-color: rgba(var(--ion-gray-step-rgb-300), 0.2) !important;
      .page-title {
        background: rgba(var(--ion-gray-step-rgb-300), 0.5) !important;
      }
    }

    .button-slider {
      .section-button-slide {
        ion-card {
          background: #000 !important;
          color: var(--ion-gray-color-900) !important;
        }
      }
    }
    .help-button {
      background: rgba(var(--ion-gray-step-rgb-300), 0.2) !important;
      .subtitle {
        color: var(--ion-color-primary) !important;
      }
    }
    .profile-header {
      --background: var(--ion-color-step-100) !important;
    }
    .drawer-header {
      background: var(--ion-color-step-100) !important;
    }
    .profile-header {
      background: var(--ion-color-step-100) !important;
      ion-toolbar {
        --background: var(--ion-color-step-100) !important;
      }
    }

    .app-logo {
      filter: invert(1);
    }
    .hotel-logo {
      filter: invert(1);
    }
    .scene {
      background: var(--ion-background-color);
    }
    .ui-container {
      .input-square {
        box-shadow: 0 0 0 100vmax var(--ion-background-color) !important;
        .input-square-instructions {
          background: var(--ion-background-color) !important;
        }
      }
      .separator {
        border-top: 1px solid var(--ion-color-primary) !important;
        .word {
          background: var(--ion-background-color) !important;
          color: var(--ion-color-primary) !important;
        }
      }
    }
    .main-page-header {
      ion-button,
      ion-back-button,
      ion-menu-button {
        --background: var(--ion-background-color);
      }
    }
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  // .ios body.dark {
  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255, 255, 255;

  //   --ion-color-step-50: #222221;
  //   --ion-color-step-100: #2d2d2c;
  //   --ion-color-step-150: #393938;
  //   --ion-color-step-200: #454544;
  //   --ion-color-step-250: #505050;
  //   --ion-color-step-300: #5c5c5b;
  //   --ion-color-step-350: #686867;
  //   --ion-color-step-400: #737373;
  //   --ion-color-step-450: #7f7f7e;
  //   --ion-color-step-500: #8b8b8a;
  //   --ion-color-step-550: #969696;
  //   --ion-color-step-600: #a2a2a1;
  //   --ion-color-step-650: #adadad;
  //   --ion-color-step-700: #b9b9b9;
  //   --ion-color-step-750: #c5c5c5;
  //   --ion-color-step-800: #d0d0d0;
  //   --ion-color-step-850: #dcdcdc;
  //   --ion-color-step-900: #e8e8e8;
  //   --ion-color-step-950: #f3f3f3;
  // }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  // .md body.dark {
  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255, 255, 255;

  //   --ion-border-color: #222222;

  //   --ion-color-step-50: #1e1e1e;
  //   --ion-color-step-100: #2a2a2a;
  //   --ion-color-step-150: #363636;
  //   --ion-color-step-200: #414141;
  //   --ion-color-step-250: #4d4d4d;
  //   --ion-color-step-300: #595959;
  //   --ion-color-step-350: #656565;
  //   --ion-color-step-400: #717171;
  //   --ion-color-step-450: #7d7d7d;
  //   --ion-color-step-500: #898989;
  //   --ion-color-step-550: #949494;
  //   --ion-color-step-600: #a0a0a0;
  //   --ion-color-step-650: #acacac;
  //   --ion-color-step-700: #b8b8b8;
  //   --ion-color-step-750: #c4c4c4;
  //   --ion-color-step-800: #d0d0d0;
  //   --ion-color-step-850: #dbdbdb;
  //   --ion-color-step-900: #e7e7e7;
  //   --ion-color-step-950: #f3f3f3;
  // }
}
@import "~swiper/css/bundle";
